import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { apiMiddleware } from 'redux-api-middleware';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import apiAuthInjector from '../apiAuthInjector';
import collections from '../Collections/collectionsReducer';
import attendees from '../Attendees/attendeesReducer';
import prize from '../Prizes/prizeReducer';
import labels from '../Labels/labelReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = {
  collections,
  attendees,
  prize,
  labels,
};

const persistedReducer = persistReducer(persistConfig, combineReducers(rootReducer));

export default function createReduxStore(getAccessTokenSilently) {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => 
      getDefaultMiddleware({
        immutableCheck: { warnAfter: 512 },
        serializableCheck: { warnAfter: 512, ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'], },
      }).concat(apiAuthInjector(getAccessTokenSilently), apiMiddleware, sagaMiddleware)
  });

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor };
}
