/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { connect } from 'react-redux';
import { Spinner } from '@blueprintjs/core';
import { labelGamesListStyles } from './LabelsStyles';
import { NonIdealState } from '@blueprintjs/core';
import styled from '@emotion/styled';
import filterListItems from '../Utilities/filterListItems';
import GameListItem from './game-list-item'
import {
  PageHeader,
  PageHeaderText,
  PageHeaderTitle,
  PageHeaderSearch,
  PageHeaderSection
} from '../layoutComponents';

const GameSearchList = styled.div`
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const GameSearch = ({ loading, games }) => {
  const [filterText, setFilterText] = useState('');
  const filteredGames = filterListItems(games, filterText, ["name"]).slice(0, 50000);

  const onFilterTextChange = event => {
    if (event.target.value !== filterText) {
      setFilterText(event.target.value);
    }
  };

  const getMainContent = (filteredGames, loading) => {
    if (loading || filteredGames.length) {
      return (
        <>
          <PageHeaderSection>
              <PageHeaderText>Search:</PageHeaderText>
              <PageHeaderSearch
              id='game-search'
              className='bp5-dark'
              placeholder='Game Title'
              autoFocus={true}
              onChange={event => onFilterTextChange(event)}
              type='search'
              />
          </PageHeaderSection>
          <GameSearchList>
            {filteredGames.map(game => (
                <GameListItem key={game.id} game={game} />
            ))}
          </GameSearchList>
          {loading && (
            <NonIdealState>
            <Spinner />
            </NonIdealState>
          )}
        </>
      );
    } else return (
        <>
            <PageHeaderSection>
                <PageHeaderText>Search:</PageHeaderText>
                <PageHeaderSearch
                id='game-search'
                className='bp5-dark'
                placeholder='Game Title'
                autoFocus={true}
                onChange={event => onFilterTextChange(event)}
                type='search'
                />
            </PageHeaderSection>
            <NonIdealState title='No Games Found :(' />
        </>
    );
  };

  return (
    <div css={labelGamesListStyles}>
      <PageHeader>
        <PageHeaderTitle>Games</PageHeaderTitle>
      </PageHeader>
      {getMainContent(filteredGames, loading)}
    </div>
  );
};

const mapState = state => ({ loading: state.labels.loading, games: state.labels.games });

const mapDispatch = dispatch => ({
});

export default connect(
  mapState,
  mapDispatch
)(GameSearch);