import { RSAA } from 'redux-api-middleware';
import env from '../environmentVariables';

const apiRoot = env.apiUrl;

export const actionTypes = {
  getLabelsRequest: 'GET_LABELS_REQUEST',
  getLabelsSuccess: 'GET_LABELS_SUCCESS',
  getLabelsFailure: 'GET_LABELS_FAILURE',
  getAllGamesRequest: 'GET_ALL_GAMES_REQUEST',
  getAllGamesSuccess: 'GET_ALL_GAMES_SUCCESS',
  getAllGamesFailure: 'GET_ALL_GAMES_FAILURE',
  addCopiesToQueue: 'ADD_COPIES_TO_QUEUE',
  addToQueue: 'ADD_TO_QUEUE',
  removeFromQueue: 'REMOVE_FROM_QUEUE',
  toggleFetchLabelsDialog: 'TOGGLE_FETCH_LABELS_DIALOG',
  clearCopyQueue: 'CLEAR_COPY_QUEUE',
};

export const createGetCopiesAction = () => ({
    [RSAA]: {
      endpoint: `${apiRoot}/copies/getallcopies`,
      method: 'GET',
      types: [actionTypes.getAllCopiesRequest, actionTypes.getAllCopiesSuccess, actionTypes.getAllCopiesFailure]
    }
  });

  export const createGetLabelsAction = (queue, startX, startY, labelSize) => {  
    const labelsRequest = {
      catalogIds: queue.map(copy => copy.catalogID),
      startX,
      startY,
      labelSize
    };
    
  return {
    [RSAA]: {
      endpoint: `${apiRoot}/copies/generatestickers`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(labelsRequest),
      types: [
        actionTypes.getLabelsRequest,
        {
          type: actionTypes.getLabelsSuccess,
          payload: (action, state, res) => {
            return res.arrayBuffer().then(buffer => {
              // Create a Blob object from the array buffer
              const blob = new Blob([buffer], { type: 'application/pdf' });
              
              return blob;
            });
          }
        },
        actionTypes.getLabelsFailure
      ]
    }
  };
};

export const createGetAllGamesAction = () => ({
  [RSAA]: {
    endpoint: `${apiRoot}/games/`,
    method: 'GET',
    types: [actionTypes.getAllGamesRequest, actionTypes.getAllGamesSuccess, actionTypes.getAllGamesFailure]
  }
});

export const addCopiesToQueueAction = copies => ({
  type: actionTypes.addCopiesToQueue,
  copies
});

export const addToQueueAction = copy => ({
  type: actionTypes.addToQueue,
  copy
});

export const removeFromQueueAction = copy => ({
  type: actionTypes.removeFromQueue,
  copy
});

export const toggleFetchLabelsDialog = () => ({
  type: actionTypes.toggleFetchLabelsDialog
});

export const clearCopyQueue = () => ({
  type: actionTypes.clearCopyQueue
});