const initialState = {
  collections: {
    loading: false,
    errorMessages: [],
    items: [],
    selectedCollection: undefined,
    addCopyDialogOpen: false,
    deleteGameDialogOpen: false,
    deleteCopyDialogOpen: false,
    updateCopyDialogOpen: false,
    uploadCopiesDialogOpen: false,
    selectedCopy: undefined,
    savingCopy: false
  },
  attendees: {
    loading: false,
    errorMessages: [],
    items: [],
    selectedAttendee: undefined,
    addAttendeeDialogOpen: false,
    uploadAttendeesDialogOpen: false,
    updateAttendeeDialogOpen: false,
    savingAttendee: false,
    badgeTypesDialogOpen: false,
    savingBadgeTypes: false,
    badgeTypes: [],
  },
  import: {
    loading: false,
    errorMessages: []
  },
  prize: {
    loading: false,
    selectedGame: null,
    games: [],
  },
  labels: {
    loading: false,
    games: [],
    copyQueue: [],
    catalogIds: [],
    fetchLabelsDialogOpen: false,
    fetcingLabels: false,
  }
};

export default initialState;
