/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button, Intent, Dialog, H4, FormGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import {
  DIALOG_HEADER,
  DIALOG_CLOSE_BUTTON,
  DIALOG_BODY,
  DIALOG_FOOTER,
  DIALOG_FOOTER_ACTIONS
} from '@blueprintjs/core/lib/esm/common/classes';

const SaveDialog = ({
  doDelete,
  isOpen,
  close,
  save,
  saving,
  children,
  helperText = '',
  headerText = '',
  onClosed = null,
  onOpening = null,
  lazy = true,
  saveText = 'Save',
  savingText = 'Saving...'
}) => {
  const onSave = event => {
    event.preventDefault();
    save();
  };
  const onDelete = event => {
    event.preventDefault();
    doDelete();
  };

  return (
    <Dialog className='bp5-dark' isOpen={isOpen} onClosed={onClosed} onOpening={onOpening} lazy={lazy} usePortal={lazy}>
      <form>
        <div className={DIALOG_HEADER}>
          <H4>{headerText}</H4>
          <Button className={`${DIALOG_CLOSE_BUTTON} bp5-minimal`} icon={IconNames.CROSS} onClick={close} />
        </div>
        <div className={DIALOG_BODY}>
          <FormGroup helperText={helperText}>{children}</FormGroup>
        </div>
        <div className={DIALOG_FOOTER}>
          <div className={DIALOG_FOOTER_ACTIONS}>
            {headerText === 'Update Copy' && (
              <Button
                text='Delete Copy'
                type='submit'
                disabled={saving}
                rightIcon={IconNames.DELETE}
                intent={Intent.DANGER}
                onClick={onDelete}
              />
            )}
            <Button
              text={saving ? savingText : saveText}
              type='submit'
              disabled={saving}
              rightIcon={IconNames.SAVED}
              intent={Intent.SUCCESS}
              onClick={onSave}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default SaveDialog;
