import initialState from '../Redux/initialState';
import { actionTypes } from './collectionsActions';

const reducer = (state = initialState.collections, action) => {
  const payload = action.payload;
  const selectedID = state.selectedCollection ? state.selectedCollection.id : null;

  switch (action.type) {
  case actionTypes.getCollectionsRequest:     
    return { ...state, loading: true };  
  case actionTypes.getCollectionsSuccess:
    return { ...state, loading: false, items: payload.result,
      selectedCollection: payload.result.find(c => c.id === selectedID) };
  case actionTypes.getCollectionsFailure:
    return { ...state, loading: false, errors: payload.errors };
  case actionTypes.setSelectedCollection:
    return { ...state, selectedCollection: action.collection };
  case actionTypes.toggleAddCopyDialog:
    return { ...state, addCopyDialogOpen: !state.addCopyDialogOpen };
  case actionTypes.toggleDeleteGameDialog:
    return { ...state, deleteGameDialogOpen: !state.deleteGameDialogOpen };
  case actionTypes.addCopyRequest:
    return { ...state, savingCopy: true };
  case actionTypes.addCopySuccess:
    return { ...state, addCopyDialogOpen:false, savingCopy: false };
  case actionTypes.addCopyFailure:
    return { ...state, savingCopy: false, errors: payload.response.Errors };
  case actionTypes.toggleUploadCopiesDialog:
    return { ...state, uploadCopiesDialogOpen: !state.uploadCopiesDialogOpen };
  case actionTypes.uploadCopiesRequest:
    return { ...state, savingCopy: true };
  case actionTypes.uploadCopiesSuccess:
    return { ...state, savingCopy: false, uploadCopiesDialogOpen: false };
  case actionTypes.uploadCopiesFailure:
    return { ...state, savingCopy: false, errors: payload.response.Errors };
  case actionTypes.deleteGameRequest:
    return { ...state, savingCopy: true };
  case actionTypes.deleteGameSuccess:
    const newGamesList = state.selectedCollection.copies.filter(item => item.title !== action.meta.title);    
    const newSelectedCollection = {
      ...state.selectedCollection,
      copies: newGamesList
    };
    return { ...state, selectedCollection: newSelectedCollection, savingCopy: false, deleteGameDialogOpen: false };
  case actionTypes.deleteGameFailure:
    return { ...state, savingCopy: false, errors: payload.response.Errors, deleteGameDialogOpen: false };
  case actionTypes.deleteCopyRequest:
    return { ...state, savingCopy: true };
  case actionTypes.deleteCopySuccess:
    const newCopies = state.selectedCollection.copies.filter(item => item.catalogID !== action.meta.catalogID);    
    const newSelected = {
      ...state.selectedCollection,
      copies: newCopies
    };
    return { ...state, selectedCollection: newSelected, savingCopy: false, updateCopyDialogOpen: false };
  case actionTypes.deleteCopyFailure:
    return { ...state, savingCopy: false, errors: payload.errors, updateCopyDialogOpen: false };
  case actionTypes.toggleUpdateCopyDialog:
    return { ...state, updateCopyDialogOpen: !state.updateCopyDialogOpen, selectedCopy: action.copy };
  case actionTypes.updateCopyRequest:
    return { ...state, savingCopy: true };
  case actionTypes.updateCopySuccess:
    return { ...state, savingCopy: false, updateCopyDialogOpen: false };
  case actionTypes.updateCopyFailure:
    return { ...state, savingCopy: false, errors: payload.response.Errors };
  default:
    return state;
  }
};

export default reducer;
