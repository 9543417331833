import React from "react";

export const HeroBanner = () => {
  const logo = process.env.REACT_APP_HERO_BANNER_LOGO;

  return (
    <div className="hero-banner hero-banner--pink-yellow">
      <div className="hero-banner__logo">
        <img className="hero-banner__image" src={logo} alt="Board Game Library logo" />
      </div>
      <h1 className="hero-banner__headline">Board Game Library Administration</h1>
      <p className="hero-banner__description">
        This is the front-end interface to interact with the Great Plains Gaming Project Board Game Library.
      </p>
    </div>
  );
};
