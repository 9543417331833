import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createUpdateAttendeeAction, toggleUpdateAttendeeDialog } from './attendeesActions';
import LabeledInput from '../components/LabeledInput';
import SaveDialog from '../components/SaveDialog';

const UpdateAttendeeDialog = ({ saving, saveAttendee, isOpen, toggleDialog, selectedAttendee }) => {
  const [name, setName] = useState('');
  const [id, setID] = useState('');
  const [newBadgeNumber, setNewBadgeNumber] = useState('');
  const setFields = (name = '', id = '', newBadgeNum = '') => {
    setName(name);
    setID(id);
    setNewBadgeNumber(newBadgeNum);
  };

  return (
    <SaveDialog
      helperText='Update an attendee'
      headerText='Update Attendee'
      saving={saving}
      disabled={saving}
      save={() => saveAttendee(name, id, newBadgeNumber)}
      isOpen={isOpen}
      onOpening={() => setFields(selectedAttendee.name, selectedAttendee.id, selectedAttendee.badgeNumber)}
      close={toggleDialog}
      onClosed={setFields}
    >
      <LabeledInput label='Name' value={name} onChange={setName} />
      <LabeledInput label='Badge #' value={newBadgeNumber} onChange={setNewBadgeNumber} autoFocus={true} />
    </SaveDialog>
  );
};

const mapState = state => ({
  selectedAttendee: state.attendees.selectedAttendee,
  isOpen: state.attendees.updateAttendeeDialogOpen,
  saving: state.attendees.savingAttendee
});
const mapDispatch = dispatch => ({
  saveAttendee: (name, id, badgeNum) => dispatch(createUpdateAttendeeAction(name, id, badgeNum)),
  toggleDialog: () => dispatch(toggleUpdateAttendeeDialog())
});

export default connect(
  mapState,
  mapDispatch
)(UpdateAttendeeDialog);
