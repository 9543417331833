import { RSAA } from 'redux-api-middleware';
import env from '../environmentVariables';

const apiRoot = env.apiUrl;

export const actionTypes = {
  getCollectionsRequest: 'GET_COLLECTIONS_REQUEST',
  getCollectionsSuccess: 'GET_COLLECTIONS_SUCCESS',
  getCollectionsFailure: 'GET_COLLECTIONS_FAILURE',
  toggleAddCopyDialog: 'TOGGLE_ADD_COPY_DIALOG',
  toggleDeleteGameDialog: 'TOGGLE_DELETE_GAME_DIALOG',
  addCopyRequest: 'ADD_COPY_REQUEST',
  addCopySuccess: 'ADD_COPY_SUCCESS',
  addCopyFailure: 'ADD_COPY_FAILURE',
  toggleUploadCopiesDialog: 'TOGGLE_UPLOAD_COPIES_DIALOG',
  uploadCopiesRequest: 'UPLOAD_COPIES_REQUEST',
  uploadCopiesSuccess: 'UPLOAD_COPIES_SUCCESS',
  uploadCopiesFailure: 'UPLOAD_COPIES_FAILURE',
  toggleUpdateCopyDialog: 'TOGGLE_UPDATE_COPY_DIALOG',
  updateCopyRequest: 'UPDATE_COPY_REQUEST',
  updateCopySuccess: 'UPDATE_COPY_SUCCESS',
  updateCopyFailure: 'UPDATE_COPY_FAILURE',
  setSelectedCollection: 'SET_SELECTED_COLLECTION',
  deleteGameRequest: 'DELETE_GAME_REQUEST',
  deleteGameSuccess: 'DELETE_GAME_SUCCESS',
  deleteGameFailure: 'DELETE_GAME_FAILURE',
  deleteCopyRequest: 'DELETE_COPY_REQUEST',
  deleteCopySuccess: 'DELETE_COPY_SUCCESS',
  deleteCopyFailure: 'DELETE_COPY_FAILURE'
};

export const createGetCollectionsAction = () => ({
  [RSAA]: {
    endpoint: `${apiRoot}/copycollections/`,
    method: 'GET',
    types: [actionTypes.getCollectionsRequest, actionTypes.getCollectionsSuccess, actionTypes.getCollectionsFailure]
  }
});

export const setSelectedCollectionAction = collection => ({
  type: actionTypes.setSelectedCollection,
  collection
});

export const toggleAddCopyDialog = () => ({ type: actionTypes.toggleAddCopyDialog });

export const toggleDeleteGameDialog = () => ({ type: actionTypes.toggleDeleteGameDialog });

export const createDeleteGameAction =  (title) => {
  return {
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${apiRoot}/games/delete/${title}`,
      method: 'GET',
      types: [actionTypes.deleteGameRequest, { type: actionTypes.deleteGameSuccess, meta: { title } }, actionTypes.deleteGameFailure]
    }
  };
};

export const createDeleteCopyAction =  (id) => {
  return {
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${apiRoot}/copies/delete/${id}`,
      method: 'GET',
      types: [actionTypes.deleteCopyRequest, { type: actionTypes.deleteCopySuccess, meta: { catalogID: id } }, actionTypes.deleteCopyFailure]
    }
  };
};

export const toggleUpdateCopyDialog = copy => ({ 
  type: actionTypes.toggleUpdateCopyDialog, copy 
});
export const toggleUploadCopiesDialog = () => ({ type: actionTypes.toggleUploadCopiesDialog });

export const createAddCopyAction = (collection, gameTitle, copyId, winnable, addToQueue) => {
  const collId = collection.id;

  return {
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${apiRoot}/copycollections/${collId}/copies`,
      body: JSON.stringify({
        id: collId,
        title: gameTitle,
        catalogId: copyId,
        winnable: winnable
      }),
      method: 'POST',
      types: [actionTypes.addCopyRequest, { type: actionTypes.addCopySuccess, meta: { addToQueue } }, actionTypes.addCopyFailure]
    }
  };
};

export const createUploadCopiesAction = (collection, files, addToQueue) => {
  const collId = collection.id;
  const formData = new FormData();
  formData.append('file', files[0], files[0].name);

  return {
    [RSAA]: {
      endpoint: `${apiRoot}/copycollections/${collId}/copies/upload`,
      body: formData,
      method: 'POST',
      types: [actionTypes.uploadCopiesRequest, { type: actionTypes.uploadCopiesSuccess, meta: { addToQueue } }, actionTypes.uploadCopiesFailure]
    }
  };
};

export const createUpdateCopyAction = (gameTitle, originalCopyId, newCopyId, newCatalogId, newCollectionId, winnable, addToQueue) => {
  return {
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${apiRoot}/copies/${originalCopyId}`,
      body: JSON.stringify({
        title: gameTitle,
        libraryId: newCopyId,
        catalogId: newCatalogId,
        collectionId: newCollectionId,
        winnable
      }),
      method: 'PUT',
      types: [actionTypes.updateCopyRequest, {type: actionTypes.updateCopySuccess, meta: { addToQueue } }, actionTypes.updateCopyFailure]
    }
  };
};
