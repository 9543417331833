/** @jsxImportSource @emotion/react */
import { connect } from 'react-redux';
import PlayerRow from './player-row';
import { playersListStyles } from './PrizesStyles';

const Players = ({ players }) => {
  return (
    <>
      <div css={playersListStyles}>
        {players.map(player => (
          <PlayerRow key={player.id} player={player} />
        ))}
      </div>
    </>
  );
};

export default Players;