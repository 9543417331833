/** @jsxImportSource @emotion/react */
import { connect } from 'react-redux';
import { Card, Spinner } from '@blueprintjs/core';
import { gameListStyles, listItemStyles, selectedListItemStyles } from './PrizesStyles';
import { NonIdealState } from '@blueprintjs/core';
import { setSelectedGameAction } from './prizeActions';
import styled from '@emotion/styled';
import { PageHeaderTitle, PageHeader } from '../layoutComponents';

const GameCard = styled(Card)`
  display: flex;
  justify-content: space-between;
`;

const getMainContent = (games, loading, selectedGame, selectGame) => {
  if (loading || (games && games.length)) {
    return (
      <>
        {games && games.length !== undefined ? games.map(game => (
          <GameCard
            css={game === selectedGame ? selectedListItemStyles : listItemStyles}
            key={game.copies[0].catalogID}
            onClick={() => selectGame(game)}
          >
            {game.title}
          </GameCard>
        )) : <div></div>}
        {loading && (
          <NonIdealState>
            <Spinner />
          </NonIdealState>
        )}
      </>
    );
  } else return <NonIdealState title='No Winnable Games Found >:(' />;
};

const WinnableGames = (props) => {
  const { games, loading, selectedGame, selectGame } = props;

  return (
    <div css={gameListStyles}>
      <PageHeader>
        <PageHeaderTitle>Winnable Games</PageHeaderTitle>
      </PageHeader>
      {getMainContent(games, loading, selectedGame, selectGame)}
    </div>
  );
};

const mapState = state => ({ 
  selectedGame: state.prize.selectedGame,
  loading: state.prize.loading,
  games: state.prize.games
});

const mapDispatch = dispatch => ({ selectGame: game => dispatch(setSelectedGameAction(game)) });

export default connect(
  mapState,
  mapDispatch
)(WinnableGames);