import { RSAA } from 'redux-api-middleware';
import env from '../environmentVariables';

const apiRoot = env.apiUrl;

export const actionTypes = {
  getWinnableGamesRequest: 'GET_WINNABLE_GAMES_REQUEST',
  getWinnableGamesSuccess: 'GET_WINNABLE_GAMES_SUCCESS',
  getWinnableGamesFailure: 'GET_WINNABLE_GAMES_FAILURE',
  setSelectedGame: 'SET_SELECTED_GAME',
  getGameWinnersRequest: 'GET_GAME_WINNERS_REQUEST',
  getGameWinnersSuccess: 'GET_GAME_WINNERS_SUCCESS',
  getGameWinnersFailure: 'GET_GAME_WINNERS_FAILURE',
  resetGameWinnersRequest: 'RESET_GAME_WINNERS_REQUEST',
  resetGameWinnersSuccess: 'RESET_GAME_WINNERS_SUCCESS',
  resetGameWinnersFailure: 'RESET_GAME_WINNERS_FAILURE',
  removePlayerFromGame: 'REMOVE_PLAYER_FROM_GAME'
};

export const createGetWinnableGamesAction = () => ({
  [RSAA]: {
    endpoint: `${apiRoot}/prizes/getwinnablegames`,
    method: 'GET',
    types: [actionTypes.getWinnableGamesRequest, actionTypes.getWinnableGamesSuccess, actionTypes.getWinnableGamesFailure]
  }
});

export const setSelectedGameAction = game => ({
  type: actionTypes.setSelectedGame,
  game
});

export const removePlayerFromGameAction = (game, player) => ({
  type: actionTypes.removePlayerFromGame,
  game,
  player
});

export const createGetGameWinnersAction = (winnableGames) => {
  
  const transformedGames = winnableGames.map(game => ({
    title: game.title,
    copies: game.copies.map(copy => ({
      id: copy.id,
      catalogID: copy.catalogID,
      winnable: copy.winnable
    })),
    players: game.players.map(player => ({
      id: player.id,
      name: player.name,
      wantsToWin: player.wantsToWin
    }))
  }));

  return {
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${apiRoot}/prizes/getgamewinners`,
      body: JSON.stringify({
        games: transformedGames
      }),
      method: 'POST',
      types: [actionTypes.getGameWinnersRequest, actionTypes.getGameWinnersSuccess, actionTypes.getGameWinnersFailure]
    }
  };
};

export const createResetGameWinnersAction = () => {
  return {
    [RSAA]: {
      endpoint: `${apiRoot}/prizes/resetgamewinners`,
      method: 'GET',
      types: [actionTypes.resetGameWinnersRequest, actionTypes.resetGameWinnersSuccess, actionTypes.resetGameWinnersFailure]
    }
  };
};