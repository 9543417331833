import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavBar } from "../components/navigation/desktop/nav-bar";
import { MobileNavBar } from "../components/navigation/mobile/mobile-nav-bar";
import { RowPage } from '../layoutComponents';
import { createGetWinnableGamesAction } from './prizeActions';
import WinnableGames from './winnable-games';
import Players from './players';
import WinnerButtons from './winner-buttons';

const PrizePicker = ({ loading, winnableGames, players, gameWinners, requestWinnableGames }) => {
    useEffect(() => {
      requestWinnableGames();
    }, [requestWinnableGames]);

    return (
        <div className="page-layout">
          <NavBar />
          <MobileNavBar />
          <div className="page-layout__content">
            <RowPage>
                <WinnableGames games={winnableGames} loading={loading} />
                <Players players={players} />
                <WinnerButtons />
            </RowPage> 
          </div>
        </div>
    );
}

const mapState = state => {
  const selectedGamePlayers = state.prize.selectedGame
    ? state.prize.selectedGame.players
    : [];
    
  return {
    winnableGames: state.prize.games,
    loading: state.prize.loading,
    players: selectedGamePlayers,
    gameWinners: state.prize.gameWinners
  };
};

const mapDispatch = dispatch => ({
  requestWinnableGames: () => {
    const action = createGetWinnableGamesAction();
    dispatch(action);
  }
});

export default connect(
    mapState,
    mapDispatch
  )(PrizePicker);