/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import LabeledInput from '../../components/LabeledInput';
import SaveDialog from '../../components/SaveDialog';
import { createUpdateCopyAction } from '../collectionsActions';
import { Select, Option } from '../../components/Select';
import { Checkbox, Label, Alert, Intent } from '@blueprintjs/core';

const noop = () => {};

const UpdateCopyDialog = ({
  close,
  saveCopy,
  savingCopy,
  isOpen,
  collections,
  copy,
  originalId,
  selectedCollection,
  deleteCopy,
  onClosed = noop
}) => {
  const [localTitle, setTitle] = useState('');
  const [localCopyId, setCopyId] = useState('');
  const [localCatalogId, setCatalogId] = useState('');
  const [localCollectionId, setCollectionId] = useState('');
  const [localWinnable, setWinnable] = useState(false);
  const [localAddToLabelQueue, setAddToLabelQueue] = useState(false);
  const [confirmDialogOpen, setConfirmOpen] = useState(false);
  const setFields = (upTitle, upCopyId, upCatalogId, upCollId, winnable, addToQueue) => {
    setTitle(upTitle);
    setCopyId(upCopyId);
    setCatalogId(upCatalogId);
    setCollectionId(upCollId);
    setWinnable(winnable);
    setAddToLabelQueue(addToQueue);
  };

  const confirmDialogClosing = () => {
    setConfirmOpen(false);
  };

  const handleDelete = () => {
    deleteCopy(copy.catalogID);
    setConfirmOpen(false);
  };

  return (
    <SaveDialog
      helperText='Update a copy of a game'
      close={close}
      headerText='Update Copy'
      saving={savingCopy}
      disabled={savingCopy}
      save={() => saveCopy(localTitle, originalId, localCopyId, localCatalogId, localCollectionId, localWinnable, localAddToLabelQueue)}
      doDelete={() => setConfirmOpen(true)}
      isOpen={isOpen}
      onOpening={() => setFields(copy.title, copy.id, copy.catalogID, selectedCollection.id, copy.winnable, false)}
      onClosed={() => {
        setFields('', '', '', '', false, false);
        onClosed();
      }}
    >
      {copy && (
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Confirm"
          icon="info-sign"
          intent={Intent.DANGER}
          isOpen={confirmDialogOpen}
          onCancel={confirmDialogClosing}
          onConfirm={handleDelete}
        >
          <p>Are you sure you want to delete this copy of {copy.title}?</p>
        </Alert>
      )}
      <LabeledInput
        label='Game Title'
        placeholder='Azul'
        large={true}
        value={localTitle}
        onChange={setTitle}
        autoFocus={true}
      />
      <LabeledInput label='Copy ID' placeholder='ABC123' large={true} value={localCopyId} disabled={true} onChange={setCopyId} />
      <LabeledInput label='Catalog ID' placeholder='GPGP-1234-5678' large={true} value={localCatalogId} onChange={setCatalogId} />
      <Select
        large={true}
        labelText={'Collection'}
        defaultValue={selectedCollection.id}
        onChange={event => setCollectionId(event.target.selectedOptions[0].value)}
      >
        {collections.map(collection => (
          <Option key={collection.id} value={collection.id}>
            {collection.name}
          </Option>
        ))}
      </Select>
      <Label>Winnable</Label>
      <Checkbox checked={localWinnable} onChange={() => setWinnable(!localWinnable)} />
      <Label>Add to Label Queue</Label>
      <Checkbox checked={localAddToLabelQueue} onChange={() => setAddToLabelQueue(!localAddToLabelQueue)} />
    </SaveDialog>
  );
};
const mapState = state => ({
  selectedCollection: state.collections.selectedCollection,
  originalId: state.collections.selectedCopy && state.collections.selectedCopy.id,
  copy: state.collections.selectedCopy,
  isOpen: state.collections.updateCopyDialogOpen,
  collections: state.collections.items,
  savingCopy: state.collections.savingCopy,
  errors: state.collections.errorMessages
});
const mapDispatch = dispatch => ({
  saveCopy: (title, originalCopyId, newCopyId, newCatalogId, newCollectionId, winnable, addToQueue) =>
    dispatch(createUpdateCopyAction(title, originalCopyId, newCopyId, newCatalogId, newCollectionId, winnable, addToQueue))
});

export default connect(
  mapState,
  mapDispatch
)(UpdateCopyDialog);
