/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import colorPalette from '../colorPalette';
import { scrollbarStyles } from '../styles';

const columnStyles = `
  background-color: ${colorPalette.GRAY4};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const labelGamesListStyles = css`
  ${columnStyles}
  height: 100%;
  border-right: 1px solid ${colorPalette.DARK_GRAY5};
  width: 30%;
  justify-content: flex-start;
  ${scrollbarStyles}
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 25%;
    border-right: 0px;
  }
`;

export const copyListStyles = css`
  ${columnStyles}
  height: 100%;
  border-right: 1px solid ${colorPalette.DARK_GRAY5};
  width: 80%;
  justify-content: flex-start;
  ${scrollbarStyles}
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 25%;
    border-right: 0px;
  }
`;