import initialState from '../Redux/initialState';
import { actionTypes } from './labelActions';
import { actionTypes as collectionActionTypes } from '../Collections/collectionsActions';

const reducer = (state = initialState.labels, action) => {
    const payload = action.payload;
  
    switch (action.type) {
      case actionTypes.getLabelsRequest:
        return { ...state, loading: true };
      case actionTypes.getLabelsSuccess:
        // Create a Blob object from the response data
        const blob = new Blob([action.payload], { type: 'application/pdf' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'labels.pdf'); // Set the filename for the download

        // Simulate a click on the link to trigger the file download
        link.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);

        return {
          ...state,
          loading: false
        };
      case actionTypes.getLabelsFailure:
        return { ...state, loading: false, errors: payload.errors };
      case actionTypes.getAllGamesRequest:
        return { ...state, loading: true };
      case actionTypes.getAllGamesSuccess:
        return {
          ...state,
          loading: false,
          games: action.payload.games,
        };
      case actionTypes.getAllGamesFailure:
        return { ...state, loading: false, errors: payload.errors };
      case actionTypes.addCopiesToQueue:
        let newQueue = [...state.copyQueue, ...action.copies.filter(newCopy => !state.copyQueue.some(existingCopy => existingCopy.catalogID === newCopy.catalogID))];
        return { 
          ...state, 
          copyQueue: newQueue 
        };
      case actionTypes.addToQueue:
        return { 
          ...state, 
          copyQueue: !state.copyQueue.some(existingCopy => existingCopy.catalogID === action.copy.catalogID) 
            ? [...state.copyQueue, action.copy]
            : state.copyQueue
        };
      case actionTypes.removeFromQueue:
        return { 
          ...state, 
          copyQueue: state.copyQueue.filter(copy => copy.catalogID !== action.copy.catalogID) 
        };
      case actionTypes.toggleFetchLabelsDialog:
        return {
          ...state,
          loading: false,
          fetchLabelsDialogOpen: !state.fetchLabelsDialogOpen
        };
      case actionTypes.clearCopyQueue:
        return {
          ...state,
          copyQueue: []
        };
      case collectionActionTypes.updateCopySuccess:
      case collectionActionTypes.addCopySuccess:
        let addToQueue = action.meta.addToQueue;
        let newCopy = payload.response;
        let newCopyQueue = state.copyQueue;
        if(addToQueue){
          const isDuplicate = state.copyQueue.some(copy => copy.catalogID === newCopy.catalogID);
          if (!isDuplicate) {
            newCopyQueue = [...state.copyQueue, newCopy];
          }
        }
        return { ...state, copyQueue: newCopyQueue };
      case collectionActionTypes.uploadCopiesSuccess:
        let add = action.meta.addToQueue;
        let newCopies = payload.response.successfulCopies;
        let newCopiesQueue = state.copyQueue;
        if(add){
          newCopies.forEach(nc => {
            const isDuplicate = newCopiesQueue.some(copy => copy.catalogID === nc.catalogID);
            if (!isDuplicate) {
              newCopiesQueue = [...newCopiesQueue, nc];
            }
          });
        }
        return { ...state, copyQueue: newCopiesQueue };
      default:
        return state;
    }
  };
  
  export default reducer;