/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import colorPalette from '../colorPalette';
import { scrollbarStyles } from '../styles';

export const listItemStyles = css`
  color: ${colorPalette.LIGHT_GRAY5};
  font-weight: bold;
  align-content: center;
  background-color: ${colorPalette.BLUE3};
  width: 100%;
  border-radius: 0;

  :hover {
    background-color: ${colorPalette.BLUE2};
    cursor: pointer;
  }
`;

export const selectedListItemStyles = css`
  ${listItemStyles};
  background-color: ${colorPalette.BLUE1};
  color: ${colorPalette.GREEN5};
`;

const columnStyles = `
  background-color: ${colorPalette.GRAY4};
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const gameListStyles = css`
  ${columnStyles}
  height: 100%;
  border-right: 1px solid ${colorPalette.DARK_GRAY5};
  width: 20%;
  justify-content: flex-start;
  ${scrollbarStyles}
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 25%;
    border-right: 0px;
  }
`;

export const playersListStyles = css`
  ${columnStyles}
  height: 100%;
  border-right: 1px solid ${colorPalette.DARK_GRAY5};
  width: 60%;
  justify-content: flex-start;
  ${scrollbarStyles}
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 25%;
    border-right: 0px;
  }
`;

export const playerTileStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 62px;
  align-items: center;
`;
