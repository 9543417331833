import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavBar } from "../components/navigation/desktop/nav-bar";
import { MobileNavBar } from "../components/navigation/mobile/mobile-nav-bar";
import { RowPage } from '../layoutComponents';
import { createGetLabelsAction, createGetAllGamesAction } from './labelActions';
import GameSearch from './game-search';
import CopyQueue from './copy-queue';

const Labels = ({requestLabels, requestAllGames, queue, games}) => {
    useEffect(() => {
      //requestLabels();
      requestAllGames();
    }, [requestLabels, requestAllGames]);

    return (
        <div className="page-layout">
          <NavBar />
          <MobileNavBar />
          <div className="page-layout__content">
            <RowPage>
              <GameSearch games={games}/>
              <CopyQueue queue={queue}/>
            </RowPage> 
          </div>
        </div>
    );
};

const mapState = state => {    
  return {
    queue: state.labels.queue,
    games: state.labels.games
  };
};

const mapDispatch = dispatch => ({
    requestLabels: () => {
        const action = createGetLabelsAction();
        dispatch(action);
    },
    requestAllGames: () => {
        const action = createGetAllGamesAction();
        dispatch(action);
    }
});

export default connect(
    mapState,
    mapDispatch
  )(Labels);