import { RSAA } from 'redux-api-middleware';

const authMiddleware = (getAccessTokenSilently) => (store) => (next) => async (action) => {
  const callApi = RSAA ? action[RSAA] : null;
  
  if (callApi) {
    const accessToken = await getAccessTokenSilently();
    callApi.headers = Object.assign({}, callApi.headers, {
      Authorization: `Bearer ${accessToken}` || ''
    });
  }

  return next(action);
};

export default authMiddleware;