import initialState from '../Redux/initialState';
import { actionTypes } from './attendeesActions';

const reducer = (state = initialState.attendees, action) => {
  const payload = action.payload;

  switch (action.type) {
  case actionTypes.getAttendeesRequest:
    return { ...state, loading: true };
  case actionTypes.getAttendeesSuccess:
    return {
      ...state,
      loading: false,
      items: payload.attendees
    };
  case actionTypes.getAttendeesFailure:
    return { ...state, loading: false, errors: payload.response == null ? payload.message : payload.response.error };
  case actionTypes.setSelectedAttendee:
    return { ...state, selectedAttendee: action.attendee };
  case actionTypes.toggleAddAttendeeDialog:
    return { ...state, addAttendeeDialogOpen: !state.addAttendeeDialogOpen };
  case actionTypes.addAttendeeRequest:
    return { ...state, savingAttendee: true };
  case actionTypes.addAttendeeSuccess:
    return { ...state, savingAttendee: false, addAttendeeDialogOpen: false };
  case actionTypes.addAttendeeFailure:
    return { ...state, savingAttendee: false, errors: payload.response.error };
  case actionTypes.toggleBadgeTypesDialog:
    return { ...state, badgeTypesDialogOpen: !state.badgeTypesDialogOpen };
  case actionTypes.getBadgeTypesRequest:
    return { ...state, savingBadgeTypes: true };
  case actionTypes.getBadgeTypesSuccess:
    return { 
      ...state, 
      savingBadgeTypes: false, 
      badgeTypes: payload.badgeTypes 
    };
  case actionTypes.getBadgeTypesFailure:
    return { ...state, savingBadgeTypes: false, errors: payload.response.error };
  case actionTypes.saveBadgeTypesRequest:
    return { ...state, savingAttendee: true };
  case actionTypes.saveBadgeTypesSuccess:
    return { ...state, savingAttendee: false, badgeTypesDialogOpen: false };
  case actionTypes.saveBadgeTypesFailure:
    return { ...state, savingAttendee: false, errors: payload.message };
  case actionTypes.toggleUploadAttendeesDialog:
    return { ...state, uploadAttendeesDialogOpen: !state.uploadAttendeesDialogOpen };
  case actionTypes.uploadAttendeesRequest:
    return { ...state, savingAttendee: true };
  case actionTypes.uploadAttendeesSuccess:
    return { ...state, savingAttendee: false, uploadAttendeesDialogOpen: false };
  case actionTypes.uploadAttendeesFailure:
    return { ...state, savingAttendee: false, errors: payload.response.error };
  case actionTypes.toggleUpdateAttendeeDialog:
    return { ...state, selectedAttendee: action.attendee, updateAttendeeDialogOpen: !state.updateAttendeeDialogOpen };
  case actionTypes.updateAttendeeRequest:
    return { ...state, savingAttendee: true };
  case actionTypes.updateAttendeeSuccess:
    return { ...state, savingAttendee: false, updateAttendeeDialogOpen: false };
  case actionTypes.updateAttendeeFailure:
    return { ...state, savingAttendee: false, errors: payload.response.error };
  default:
    return state;
  }
};

export default reducer;
