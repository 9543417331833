/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { connect } from 'react-redux';
import LabeledInput from '../components/LabeledInput';
import SaveDialog from '../components/SaveDialog';
import { createGetLabelsAction } from './labelActions';
import { Label, Radio } from '@blueprintjs/core';

const noop = () => {};

const FetchLabelsDialog = ({
  close,
  queue,
  fetchLabels,
  fetchingLabels,
  isOpen,
  onClosed = noop
}) => {
  const [startX, setStartX] = useState(1);
  const [startY, setStartY] = useState(1);
  const [labelSize, setLabelSize] = useState('Regular');

  const handleRadioChange = (e) => {
    setLabelSize(e.target.value);
  };

  return (
    <SaveDialog
      helperText='Fetch the Labels that are in the Queue as a PDF'
      close={close}
      headerText='Fetch Labels'
      saving={fetchingLabels}
      disabled={fetchingLabels}
      save={() => fetchLabels(queue, startX, startY, labelSize)}
      saveText='Fetch'
      savingText='Fetching...'
      isOpen={isOpen}
      onClosed={() => {
        setStartX(1);
        setStartY(1);
        onClosed();
      }}
    >
      <LabeledInput
        label='Start X'
        placeholder='1'
        large={true}
        value={startX}
        onChange={setStartX}
        autoFocus={true}
        />
        <LabeledInput
        label='Start Y'
        placeholder='1'
        large={true}
        value={startY}
        onChange={setStartY}
        autoFocus={false}
      />
      <Label>Label Size</Label>
      <Radio value='Regular' checked={labelSize === 'Regular'} onChange={handleRadioChange}>Regular</Radio>
      <Radio value='Large' checked={labelSize === 'Large'} onChange={handleRadioChange}>Large</Radio>
    </SaveDialog>
  );
};

const mapState = state => ({
  isOpen: state.labels.fetchLabelsDialogOpen,
  queue: state.labels.copyQueue,
  fetchingLabels: state.labels.fetchingLabels
});

const mapDispatch = dispatch => ({
    fetchLabels: (queue, startX, startY, labelSize) =>
    dispatch(createGetLabelsAction(queue, startX, startY, labelSize))
});

export default connect(
  mapState,
  mapDispatch
)(FetchLabelsDialog);