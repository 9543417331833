import React, { useState } from 'react';
import { connect } from 'react-redux';
import LabeledInput from '../../components/LabeledInput';
import SaveDialog from '../../components/SaveDialog';
import { createAddCopyAction } from '../collectionsActions';
import { Checkbox, Label } from '@blueprintjs/core';

const AddCopyDialog = ({ collection, isOpen, close, saveCopy, savingCopy, onClosed, title = '', copyId = '', addToLabelQueue = false }) => {
  const [localTitle, setTitle] = useState('');
  const [localCopyId, setCopyId] = useState('');
  const [localWinnable, setWinnable] = useState(false);
  const [localAddToLabelQueue, setAddToLabelQueue] = useState(false);
  const setFields = (upTitle, upCopyId, upWinnable, upAddToQueue) => {
    setTitle(upTitle);
    setCopyId(upCopyId);
    setWinnable(upWinnable);
    setAddToLabelQueue(upAddToQueue);
  };

  return (
    <SaveDialog
      helperText={`Add a copy of a game to the ${collection ? `${collection.name}` : 'chosen'} collection`}
      close={close}
      headerText='Add Game'
      saving={savingCopy}
      disabled={savingCopy}
      save={() => saveCopy(collection, localTitle, localCopyId, localWinnable, localAddToLabelQueue)}
      isOpen={isOpen}
      onOpening={() => setFields(title, copyId, collection.allowWinning, addToLabelQueue)}
      onClosed={() => {
        setFields('', '', false, false);
        onClosed();
      }}
    >
      <LabeledInput
        label='Game Title'
        placeholder='Game Title: The Game'
        large={true}
        value={localTitle}
        onChange={setTitle}
        autoFocus={true}
      />
      <LabeledInput label='Barcode ID' placeholder='Leave this blank to generate on save' large={true} value={localCopyId} onChange={setCopyId} />
      <Label>Winnable</Label>
      <Checkbox checked={localWinnable} onChange={() => setWinnable(!localWinnable)} />
      <Label>Add to Label Queue</Label>
      <Checkbox checked={localAddToLabelQueue} onChange={() => setAddToLabelQueue(!localAddToLabelQueue)} />
    </SaveDialog>
  );
};
const mapState = state => ({
  collection: state.collections.selectedCollection,
  savingCopy: state.collections.savingCopy,
  errors: state.collections.errorMessages,
  isOpen: state.collections.addCopyDialogOpen
});
const mapDispatch = dispatch => ({
  saveCopy: (coll, title, copyId, winnable, addToQueue) => dispatch(createAddCopyAction(coll, title, copyId, winnable, addToQueue))
});

export default connect(
  mapState,
  mapDispatch
)(AddCopyDialog);
