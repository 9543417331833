import React from 'react';
import { connect } from 'react-redux';
import UploadDialog from '../components/UploadDialog';
import { toggleUploadAttendeesDialog, createUploadAttendeesAction, createGetAttendeesAction } from './attendeesActions';

const AttendeesUploadDialog = ({ toggle, uploadFiles, getAttendees, uploading, isOpen }) => {
  const handleUpload = async (files) => {
    const success = await uploadFiles(files);
    if (success) {
      getAttendees();
    }
  };

  return (
    <UploadDialog
      headerText='Attendees Upload'
      upload={handleUpload}
      close={toggle}
      isOpen={isOpen}
      uploading={uploading}
      hasLabels={false}
    />
  );
}

const mapState = state => ({
  uploading: state.attendees.savingAttendee,
  isOpen: state.attendees.uploadAttendeesDialogOpen
});

const mapDispatch = dispatch => ({
  toggle: () => dispatch(toggleUploadAttendeesDialog()),
  uploadFiles: files => dispatch(createUploadAttendeesAction(files)),
  getAttendees: () => dispatch(createGetAttendeesAction()),
});

export default connect(
  mapState,
  mapDispatch
)(AttendeesUploadDialog);
