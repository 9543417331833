/** @jsxImportSource @emotion/react */
import { connect } from 'react-redux';
import { Button, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { removeFromQueueAction } from './labelActions';
import { playerTileStyles } from '../Prizes/PrizesStyles';
import styled from '@emotion/styled';

const CenteredContainer = styled.div`
  display: flex;
  border: 1px;
  align-items: center; /* Vertical alignment */
  justify-content: space-between; /* Add space between h5 and button */
  height: 50vh; /* Adjust the height based on your needs */
  padding: 0 20px; /* Add padding for spacing */
  border: 1px solid #333;
  border-radius: 5px;
  -webkit-transition: opacity 2s, -webkit-transform 0.3s;
  transition: opacity 2s, transform 0.3s;
  opacity: 1;
  transform: translateY(0);

  &:hover {
    background-color: orange; /* Orange background on hover */
  }

  &.removed {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    margin: 0;
    padding: 0;
  }
`;

const QueuedCopy = ({ copy, removeCopy }) => {
    const handleRemove = () => {
        removeCopy(copy);
    };
    return (
    <CenteredContainer css={playerTileStyles}>
        <h5>{copy.title} ({copy.catalogID})</h5>
        <Button
            icon={<Icon icon={IconNames.CROSS} />}
            intent="danger"
            onClick={handleRemove}            
        />
    </CenteredContainer>
    );
};

const mapState = state => ({});
  
const mapDispatch = (dispatch) => ({ 
    removeCopy: (copy) => dispatch(removeFromQueueAction(copy)),
});

export default connect(
    mapState,
    mapDispatch
  )(QueuedCopy);