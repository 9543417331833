/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AttendeesList from './AttendeesList';
import AddAttendeeDialog from './AddAttendeeDialog';
import { NavBar } from "../components/navigation/desktop/nav-bar";
import { MobileNavBar } from "../components/navigation/mobile/mobile-nav-bar";
import {
  ColumnPage,
  PageHeader,
  PageHeaderText,
  PageHeaderTitle,
  PageHeaderSection,
  PageHeaderButton,
  PageHeaderButtonSection,
  PageHeaderSearch
} from '../layoutComponents';
import { createGetAttendeesAction, toggleBadgeTypesDialog, toggleAddAttendeeDialog, toggleUploadAttendeesDialog } from './attendeesActions';
import { NonIdealState, Spinner, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import AttendeesUploadDialog from './AttendeesUploadDialog';
import BadgeTypesDialog from './BadgeTypesDialog';

const Attendees = ({ attendees, loading, requestAttendees, toggleBadgeTypesDialog, toggleAddAttendeeDialog, toggleUploadAttendeesDialog }) => {
  const [filterText, setFilterText] = useState('');
  const onFilterTextChange = text => setFilterText(text);

  useEffect(() => {
    requestAttendees();
  }, [requestAttendees]);
  let bodyContent;

  if (loading) {
    bodyContent = (
      <NonIdealState>
        <Spinner />
      </NonIdealState>
    );
  } else if (!attendees.length) {
    bodyContent = <NonIdealState>No attendees found.</NonIdealState>;
  } else {
    bodyContent = <AttendeesList attendees={attendees} filterText={filterText} />;
  }

  return (
    <div className="page-layout">
      <NavBar />
      <MobileNavBar />
      <div className="page-layout__content">
        <ColumnPage>
          <AddAttendeeDialog description='Add a new attendee' />
          <AttendeesUploadDialog />
          <BadgeTypesDialog />
          <PageHeader>
            <PageHeaderSection>
              <PageHeaderText>Search:</PageHeaderText>
              <PageHeaderSearch
                className='bp5-dark'
                placeholder='Name | Badge #'
                autoFocus={true}
                onChange={event => onFilterTextChange(event.target.value)}
                type='search'
              />
            </PageHeaderSection>
            <PageHeaderTitle>Attendees</PageHeaderTitle>
            <PageHeaderButtonSection>
              <PageHeaderButton text='Badge Types' intent={Intent.PRIMARY} onClick={toggleBadgeTypesDialog} />
              <PageHeaderButton
                text='Add Attendee'
                rightIcon={IconNames.ADD}
                intent={Intent.PRIMARY}
                onClick={toggleAddAttendeeDialog}
              />
              <PageHeaderButton text='Upload' intent={Intent.PRIMARY} onClick={toggleUploadAttendeesDialog} />
            </PageHeaderButtonSection>
          </PageHeader>
          {bodyContent}
        </ColumnPage>
      </div>
    </div>
  );
};

const mapState = state => ({
  attendees: state.attendees.items,
  loading: state.attendees.loading
});

const mapDispatch = dispatch => ({
  requestAttendees: () => {
    const action = createGetAttendeesAction();
    dispatch(action);
  },
  toggleBadgeTypesDialog: () => {
    const action = toggleBadgeTypesDialog();
    dispatch(action);
  },
  toggleAddAttendeeDialog: () => {
    const action = toggleAddAttendeeDialog();
    dispatch(action);
  },
  toggleUploadAttendeesDialog: () => {
    const action = toggleUploadAttendeesDialog();
    dispatch(action);
  },
});

export default connect(
  mapState,
  mapDispatch
)(Attendees);
