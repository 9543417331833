/** @jsxImportSource @emotion/react */
import React from 'react';
import { Card, Collapse, Button, Intent, Divider } from '@blueprintjs/core';
import { gameTileStyles, GameTileHeader, GameTileHeaderAddBtn, GameTileHeaderDeleteBtn } from '../CollectionsStyles';
import { IconNames } from '@blueprintjs/icons';
import CopyList from './CopyList';

class GameTile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { title, copies, openAddCopyDialog, openDeleteGameDialog } = this.props;

    return (
      <Card css={gameTileStyles}>
        <GameTileHeaderDeleteBtn        
          onClick={() => openDeleteGameDialog(title)}
          intent={Intent.DANGER}
          minimal={true}
          icon={IconNames.DELETE}
        />
        <GameTileHeader>
          <h3>{title}</h3>
        </GameTileHeader>
        <GameTileHeaderAddBtn
          onClick={() => openAddCopyDialog(title, '')}
          intent={Intent.PRIMARY}
          minimal={true}
          icon={IconNames.ADD}
        />
        <div>
          <Button
            text={this.state.open ? 'Hide Copies' : 'Show Copies'}
            intent={Intent.PRIMARY}
            onClick={this.toggle}
            style={{ width: '100%' }}
          />
          <Collapse isOpen={this.state.open}>
            <Divider style={{ marginTop: '2em' }} />
            <CopyList copies={copies} />
          </Collapse>
        </div>
      </Card>
    );
  }
}

export default GameTile;
