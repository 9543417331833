/** @jsxImportSource @emotion/react */
import { connect } from 'react-redux';
import { Button } from '@blueprintjs/core';
import { createGetGameWinnersAction, createResetGameWinnersAction } from './prizeActions';
import GameWinners from './game-winners';


const WinnerButtons = ({winnableGames, getWinners, resetWinners, gameWinners}) => {

    const handleGetWinners = () => {
        getWinners(winnableGames);
    };

    const handleResetWinners = () => {
        resetWinners();
    };

    return (
        <div>
            <Button onClick={handleGetWinners}>Get Winners</Button>
            <Button onClick={handleResetWinners} intent="danger">Reset Winners</Button>
            <GameWinners gameWinners={gameWinners} />
        </div>
    );
};

const mapState = state => ({
    winnableGames: state.prize.games,
    gameWinners: state.prize.gameWinners
});
  
const mapDispatch = (dispatch) => ({ 
    getWinners: (games) => dispatch(createGetGameWinnersAction(games)),
    resetWinners: () => dispatch(createResetGameWinnersAction()),
});

export default connect(
    mapState,
    mapDispatch
)(WinnerButtons);