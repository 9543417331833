import { connect } from 'react-redux';
import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import styled from '@emotion/styled';
import { toggleFetchLabelsDialog, clearCopyQueue } from './labelActions';
import FetchLabelsDialog from './fetch-labels-dialog'
import QueuedCopy from './queued-copy';
import { copyListStyles } from './LabelsStyles';
import {
  PageHeader,
  PageHeaderTitle,
  PageHeaderButtonSection,
  PageHeaderButton
} from '../layoutComponents';

const CopiesContainer = styled.div`
  width: 100%;
`;

const Copies = styled.div`
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const CopyQueue = ({queue, toggleFetchLabelsDialog, clearCopyQueue}) => {
  return (
    <CopiesContainer css={copyListStyles}>
      <FetchLabelsDialog close={toggleFetchLabelsDialog}/>
      <PageHeader>
        <PageHeaderTitle>Label Queue</PageHeaderTitle>
        <PageHeaderButtonSection>
          <PageHeaderButton
            text='Fetch Labels'
            rightIcon={IconNames.ADD}
            intent={Intent.PRIMARY}
            onClick={toggleFetchLabelsDialog}
          />
          <PageHeaderButton text='Clear Queue' intent={Intent.DANGER} onClick={clearCopyQueue} />
        </PageHeaderButtonSection>
      </PageHeader>
      <Copies>
        {queue.map(copy => (
          <QueuedCopy  key={copy.catalogID} copy={copy} />
        ))}
      </Copies>
    </CopiesContainer>
  );
};

const mapState = state => ({
  queue: state.labels.copyQueue
});

const mapDispatch = dispatch => ({
  toggleFetchLabelsDialog: () => dispatch(toggleFetchLabelsDialog()),
  clearCopyQueue: () => dispatch(clearCopyQueue())
});

export default connect(
  mapState,
  mapDispatch
)(CopyQueue);