import { useState, useEffect, useCallback } from 'react';
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import './ConfettiExplosion.css';

const ConfettiExplosion = ({isModalOpen}) => {
    const [conductor, setConductor] = useState();

    const explosion = useCallback(() => {
        conductor?.run({ speed: 3 });
        setTimeout(() => { conductor?.pause(); }, 1000);
    }, [conductor]);

    const onInit = ({ conductor }) => {
       setConductor(conductor);
    };

    useEffect(() => {
        if(isModalOpen)
        {
            explosion();
        }
    }, [isModalOpen, explosion, conductor]);    

    return (
        <div className="confetti-container">
            <Fireworks onInit={onInit} />
        </div>
    );
};

export default ConfettiExplosion;