import React, { useState } from 'react';
import { css } from '@emotion/react';
import { connect } from 'react-redux';
import { Button, Card } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import styled from '@emotion/styled';
import { addToQueueAction } from './labelActions';
import { listItemStyles } from '../Collections/CollectionsStyles';

const GameSearchCard = styled(Card)`
    display: flex;
    justify-content: space-between;
`;

const CopyListItem = styled.div`
    padding: 5px 25px 5px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.15s ease; /* Add smooth transition effect */
    
    /* Inline hover effect */
    ${({ hoverColor }) => hoverColor && css`
        &:hover {
            background-color: ${hoverColor}; /* Change to the specified hover color */
        }
    `}
`;

const AddCopyButton = styled(Button)`
`;

const GameListItem = ({ game, addToQueue }) => {
    const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleAddToQueue = (copy) => {
    addToQueue(copy);
  };

  return (
    <div>
      <GameSearchCard key={game.id} css={listItemStyles}>
        <div>{game.name}</div>
        <Button
          icon={expanded ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_LEFT}
          onClick={handleExpand}
        />
      </GameSearchCard>
      {expanded && (
        <div>
          {game.copies.map(copy => (
            <CopyListItem hoverColor="#f0f0f0" key={copy.id}>
                {copy.title.length<=10 ? copy.title : copy.title.substring(0,10) + '...'} ({copy.catalogID})
                <AddCopyButton
                    icon={IconNames.PLUS}
                    intent="success"
                    onClick={() => handleAddToQueue(copy)}
                />
            </CopyListItem>
          ))}
        </div>
      )}
    </div>
  );
};

const mapState = state => ({});

const mapDispatch = dispatch => ({
    addToQueue: (copy) => {
      dispatch(addToQueueAction(copy));
    }
});

export default connect(
  mapState,
  mapDispatch
)(GameListItem );
