import React from 'react';
import { connect } from 'react-redux';
import UploadDialog from '../../components/UploadDialog';
import { toggleUploadCopiesDialog, createUploadCopiesAction } from '../collectionsActions';

const CopiesUploadDialog = ({ collection, toggle, uploadFiles, isOpen, saving }) => (
  <UploadDialog
    headerText='Copies Upload'
    upload={(files,addToQueue) => uploadFiles(collection, files, addToQueue)}
    close={toggle}
    isOpen={isOpen}
    uploading={saving}
    hasLabels={true}
  />
);

const mapState = state => ({
  collection: state.collections.selectedCollection,
  saving: state.collections.savingCopy,
  isOpen: state.collections.uploadCopiesDialogOpen
});

const mapDispatch = dispatch => ({
  toggle: () => dispatch(toggleUploadCopiesDialog()),
  uploadFiles: (collection, files, addToQueue) => dispatch(createUploadCopiesAction(collection, files, addToQueue))
});

export default connect(
  mapState,
  mapDispatch
)(CopiesUploadDialog);
