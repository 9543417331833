import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CollectionsList from './CollectionsList';
import { createGetCollectionsAction } from './collectionsActions';
import Games from './Games/Games';
import { RowPage } from '../layoutComponents';
import _ from 'lodash';
import { NavBar } from "../components/navigation/desktop/nav-bar";
import { MobileNavBar } from "../components/navigation/mobile/mobile-nav-bar";

const Collections = ({ collections, loading, games, requestCollections }) => {
  useEffect(() => {
    requestCollections();
  }, [requestCollections]);

  return (
    <div className="page-layout">
      <NavBar />
      <MobileNavBar />
      <div className="page-layout__content">
        <RowPage>
          <CollectionsList collections={collections} loading={loading} />
          <Games games={games} />
        </RowPage>    
      </div>
    </div>
  );
};

const mapState = state => {
  const selectedCollectionCopies = state.collections.selectedCollection
    ? state.collections.selectedCollection.copies
    : [];
  const orderedSelectedCollectionCopies = _.orderBy(selectedCollectionCopies, 'title');
  return {
    collections: state.collections.items,
    loading: state.collections.loading,
    games: _.groupBy(orderedSelectedCollectionCopies, 'title')
  };
};

const mapDispatch = dispatch => ({
  requestCollections: () => {
    const action = createGetCollectionsAction();
    dispatch(action);
  }
});

export default connect(
  mapState,
  mapDispatch
)(Collections);
