/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react';
import GameTile from './GameTile';
import filterListItems from '../../Utilities/filterListItems';
import { gameListStyles, LoadMoreButton } from '../CollectionsStyles';
import { Intent } from '@blueprintjs/core';
import { PageHeaderText, PageSubheader } from '../../layoutComponents';

const GamesList = ({ games, collectionsLoading, filterText, openCopyDialog, openDeleteDialog }) => {
  const [displayCap, setDisplayCap] = useState(50);
  const titles = Object.keys(games);
  const filteredTitles = filterListItems(titles, filterText).slice(0, displayCap);
  const incrementDisplayCap = () => setDisplayCap(displayCap + 25);
  
  const listRef = useRef(null);

  //Infinite scroll logic
  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 100) {
        incrementDisplayCap();
      }
    };

    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [displayCap, titles.length]);

  return (
    <>
      <PageSubheader>
        <PageHeaderText>{`Displaying ${filteredTitles.length} of ${titles.length} games`}</PageHeaderText>
        <PageHeaderText>{collectionsLoading ? 'Refreshing...' : ''}</PageHeaderText>
      </PageSubheader>
      <div css={gameListStyles} ref={listRef}>
        {filteredTitles.map(title => (
          <GameTile key={title} title={title} copies={games[title]} openAddCopyDialog={openCopyDialog} openDeleteGameDialog={openDeleteDialog} />
        ))}
      </div>
    </>
  );
};

export default GamesList;
