import initialState from '../Redux/initialState';
import { actionTypes } from './prizeActions';

const reducer = (state = initialState.prize, action) => {
    const payload = action.payload;
    const selectedID = state.selectedGame ? state.selectedGame.catalogID : null;
  
    switch (action.type) {
    case actionTypes.getWinnableGamesRequest:
      return { ...state, loading: true };
    case actionTypes.getWinnableGamesSuccess:
      return {
        ...state,
        loading: false,
        games: payload.result,
        selectedGame: payload.result.find(c => c.catalogID === selectedID)
      };
    case actionTypes.getWinnableGamesFailure:
      return { ...state, loading: false, errors: payload.errors };
    case actionTypes.setSelectedGame:
      return { ...state, selectedGame: action.game };
    case actionTypes.getGameWinnersRequest:
      return { ...state, loading: true };
    case actionTypes.getGameWinnersSuccess:
      return { ...state, gameWinners: payload.gameWinners, loading: false };
    case actionTypes.getGameWinnersFailure:
      return { ...state, loading: false, errors: payload.response.Errors };
    case actionTypes.resetGameWinnersRequest:
      return { ...state, loading: true };
    case actionTypes.resetGameWinnersSuccess:
      return { ...state, loading: false };
    case actionTypes.resetGameWinnersFailure:
      return { ...state, loading: false, errors: payload.response.Errors };
    case actionTypes.removePlayerFromGame:
        let updatedPlayers = action.game.players;
        return { 
          ...state, 
          games: state.games.map((game) => {
            if (game.title === action.game.title) {
              updatedPlayers = game.players.filter(
                (player) => player.id !== action.player.id
              );
            
              const updatedGame = {
                ...game,
                players: updatedPlayers,
              };

              return updatedGame;
            }
            return game;
          }),
          selectedGame: {...state.selectedGame, players: updatedPlayers}
      };
    default:
      return state;
    }
  };
  
  export default reducer;