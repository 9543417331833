import React, { useState, useEffect } from 'react';
import { Button, Dialog } from '@blueprintjs/core';
import ConfettiExplosion from './confetti-explosion'

const GameWinners = ({ gameWinners }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    // Check if gameWinners array has objects and open the modal accordingly
    if (gameWinners !== undefined && gameWinners.length > 0) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [gameWinners]);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <Dialog
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Game Winners"
      >
        <div className="bp3-dialog-body">
          {gameWinners !== undefined && gameWinners.map(winner => {
            return (
                <div class="winnerRow">
                    <span class="winnerName">{winner.attendeeName}: </span><span class="winnerTitle">{winner.title}</span>
                </div>
            );
          })}
        </div>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <Button onClick={handleCloseModal}>Close</Button>
          </div>
        </div>
      </Dialog>
      <ConfettiExplosion isModalOpen={isModalOpen} /> 
    </div>
  );
};

export default GameWinners;