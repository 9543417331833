import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createSaveBadgeTypesAction, createGetBadgeTypesAction, toggleBadgeTypesDialog } from './attendeesActions';
import LabeledInput from '../components/LabeledInput';
import SaveDialog from '../components/SaveDialog';
import { Checkbox } from '@blueprintjs/core';

const BadgeTypesDialog = ({ saving, badgeTypes, getBadgeTypes, saveBadgeTypes, isOpen, toggleDialog }) => {
    
    const [localBadgeTypes, setLocalBadgeTypes] = useState([]);

    useEffect(() => {
        if (isOpen && badgeTypes) {
            setLocalBadgeTypes(badgeTypes);
        }
    }, [isOpen, badgeTypes]);

    const handleCheckboxChange = (index) => {
        const updatedBadgeTypes = [...localBadgeTypes];
        updatedBadgeTypes[index].canEnterPlayToWin = !updatedBadgeTypes[index].canEnterPlayToWin;
        setLocalBadgeTypes(updatedBadgeTypes);
    };

    const handleSave = () => {
        saveBadgeTypes(localBadgeTypes); // Save the local state to Redux
    };

    return (
        <SaveDialog
            helperText='Place a checkmark next to the badge types that are allowed to win games.'
            headerText='Update Badge Type Prize Eligibility'
            saving={saving}
            disabled={saving}
            save={() => handleSave()}
            isOpen={isOpen}
            onOpening={() => getBadgeTypes()}
            close={toggleDialog}
        >
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {badgeTypes && badgeTypes.map((badge, index) => (
                    <div key={badge.badgeType} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <div style={{ flex: 1 }}>
                            {badge.badgeType}
                        </div>
                        <Checkbox
                            checked={badge.canEnterPlayToWin}
                            onChange={() => handleCheckboxChange(index)}
                            disabled={saving}
                        >
                        </Checkbox>
                    </div>
                ))}
            </div>
        </SaveDialog>
    );
};

const mapState = state => ({
    isOpen: state.attendees.badgeTypesDialogOpen,
    saving: state.attendees.savingBadgeTypes,
    badgeTypes: state.attendees.badgeTypes,
});

const mapDispatch = dispatch => ({
    getBadgeTypes: () => dispatch(createGetBadgeTypesAction()),
    saveBadgeTypes: (badgeTypes) => dispatch(createSaveBadgeTypesAction(badgeTypes)),
    toggleDialog: () => dispatch(toggleBadgeTypesDialog()),
});

export default connect(
    mapState,
    mapDispatch
)(BadgeTypesDialog);
