import React, { useState } from 'react';
import SaveDialog from './SaveDialog';
import { FileInput, FormGroup, Icon, Intent, Checkbox, Label } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

const UploadDialog = ({
  uploadInputLabelText = 'Upload: ',
  upload,
  headerText = '',
  helperText = 'Warning: This operation can take a long time when you are uploading a large number of rows. Please be patient.',
  uploading = false,
  isOpen = false,
  close,
  hasLabels = false
}) => {
  const [files, setFiles] = useState(null);
  const [localAddToLabelQueue, setAddToLabelQueue] = useState(false);
  const fileChosen = !!files && !!files[0];
  const fileInputText = fileChosen ? files[0].name : 'Choose file...';
  
  const setFields = (upFiles, upAddToQueue) => {
    setFiles(upFiles);
    setAddToLabelQueue(upAddToQueue);
  };

  return (
    <SaveDialog
      save={() => upload(files, localAddToLabelQueue)}
      saving={uploading}
      onClosed={() => setFields('', false)}
      isOpen={isOpen}
      close={close}
      helperText={helperText}
      headerText={headerText}
    >
      <FormGroup label={uploadInputLabelText}>
        <FileInput onInputChange={event => setFiles(event.target.files)} text={fileInputText} />
      </FormGroup>
      {fileChosen && (
        <>
          <span>Ready to upload</span>
          <Icon icon={IconNames.TICK} intent={Intent.SUCCESS} />
        </>
      )}
      {hasLabels && (
        <>
          <Label>Add Successful Uploads to Label Queue</Label>
          <Checkbox checked={localAddToLabelQueue} onChange={() => setAddToLabelQueue(!localAddToLabelQueue)} />
        </>
      )}
    </SaveDialog>
  );
};

export default UploadDialog;
