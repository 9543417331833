import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import Collections from './Collections/Collections';
import Attendees from './Attendees/Attendees';
import Labels from './Labels/labels';
import PrizePicker from './Prizes/prize-picker';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './Redux/store';

export const App = () => {
  const { isLoading, getAccessTokenSilently } = useAuth0();

  const { store, persistor } = configureStore(getAccessTokenSilently);

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Provider store={store}>
      <PersistGate loading={<PageLoader />} persistor={persistor}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/collections"
            element={<AuthenticationGuard component={Collections} />}
          />
          <Route
            path="/attendees"
            element={<AuthenticationGuard component={Attendees} />}
          /> 
          <Route
            path="/prizes"
            element={<AuthenticationGuard component={PrizePicker} />}
          />
          <Route
            path="/labels"
            element={<AuthenticationGuard component={Labels} />}
          /> 
          <Route path="/callback" element={<CallbackPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </PersistGate>
    </Provider>
  );
};
